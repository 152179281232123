import fetch from 'cross-fetch';
import { identity } from 'lodash';

import Loggable from 'modules/elastic/loggable';
import isBrowser from 'utils/is-browser';

type TransformRequest = (parameters: RequestInit) => RequestInit;

interface ConstructorOptions {
    debug?: boolean;
}

interface SetupOptions {
    baseUrl: string;
    transformRequest: TransformRequest;
}

export default class ElasticQueryRunner extends Loggable {
    protected static BASE_URL = '';
    protected static TRANSFORM_REQUEST: TransformRequest = identity;
    protected static readonly PATH = '/api/v3/search/searchv3';

    constructor(options: ConstructorOptions) {
        super({ ...options, name: 'ElasticQueryRunner' });
    }

    public static setup({ baseUrl, transformRequest }: SetupOptions) {
        ElasticQueryRunner.BASE_URL = baseUrl;
        ElasticQueryRunner.TRANSFORM_REQUEST = transformRequest;
    }

    protected processError(error: Error) {
        if (isBrowser()) {
            document.dispatchEvent(new CustomEvent('show-snackbar', { detail: { message: error.message || error } }));
        }
    }

    public async run(body: unknown) {
        try {
            const requestInfo = ElasticQueryRunner.BASE_URL + ElasticQueryRunner.PATH;
            const requestInit = ElasticQueryRunner.TRANSFORM_REQUEST({
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(body)
            });
            const response = await fetch(requestInfo, requestInit);
            const json = await response.json();
            this.log('success', json);
            return json;
        } catch (error) {
            this.processError(error);
            this.log('failure', error, error.message);
            throw error;
        }
    }
}
