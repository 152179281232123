import { isNil, isObjectLike } from 'lodash';

type Predicate<T> = (value: T[keyof T], key: keyof T) => boolean;

type Options = {
    recursive?: boolean;
};

const defaultPredicate = <T>(value: T) => !isNil(value);

const filterObject = <T extends object>(
    obj: T,
    predicate: Predicate<T> = defaultPredicate,
    options: Options = {}
): T => {
    const { recursive } = options;

    return Object.entries(obj).reduce((acc, entry) => {
        const key = entry[0] as keyof T;
        const value = entry[1] as T[keyof T];

        if (predicate(value, key)) {
            if (recursive && isObjectLike(value)) {
                acc[key] = filterObject(value as T, predicate, options) as T[keyof T];
            } else {
                acc[key] = value;
            }
        }

        return acc;
    }, {} as T);
};

export default filterObject;
