import fetch from 'cross-fetch';
import { action, makeObservable, observable } from 'mobx';

import Cacheable from 'app/store/Cacheable';
import { logger } from 'services/logger';

type Post = {
    date: string;
    image: string;
    link: string;
    timeToRead: string;
    title: string;
};

export default class BlogStore extends Cacheable {
    static PATH = '/blog/feed.json';
    static HOSTNAME = 'https://vulners.com';
    static FALLBACK_POSTS: Post[] = [
        {
            image: '/blog/assets/img/2022-04-08-000.png',
            title: 'Spring vulnerabilities - Log4j flashbacks',
            date: '8 Apr 2022 12:00 AM',
            timeToRead: '3 min. read',
            link: '/blog/2022-04-spring-vulnerabilities-log4j-flashbacks/'
        },
        {
            image: '/blog/assets/img/2021-07-29-001.png',
            title: 'Timely patching for your WordPress with Vulners scanner',
            date: '29 Jul 2021',
            timeToRead: '10 min. read 12:00 AM',
            link: '/blog/2021-07-vulners-wordpress-scanner/'
        },
        {
            image: '/blog/assets/img/OctoHackat.png',
            title: 'Vulners got all exploits from GitHub',
            date: '17 Nov 2021 12:00 AM',
            timeToRead: '3 min. read',
            link: '/blog/2021-11-vulners-got-all-exploits-from-github/'
        }
    ];

    @observable posts: Post[] = [];
    @observable isLoading = false;

    controller: AbortController | null = null;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    consume(cachedEntry) {
        if (cachedEntry.posts) {
            this.posts = cachedEntry.posts;
        }
    }

    protected getAbsoluteUrl = (url: string) => {
        try {
            return new URL(url, BlogStore.HOSTNAME).href;
        } catch (_err) {
            return url;
        }
    };

    protected processPost = (post: Post) => {
        return { ...post, image: this.getAbsoluteUrl(post.image), link: this.getAbsoluteUrl(post.link) };
    };

    @action loadPosts = async () => {
        if (this.hasItemInCache(BlogStore.PATH)) {
            return this.consume(this.getItemFromCache(BlogStore.PATH));
        }

        this.isLoading = true;
        try {
            const response = await fetch(this.getAbsoluteUrl(BlogStore.PATH));
            const json: Post[] = await response.json();
            this.posts = json.map((post) => this.processPost(post));
            this.setItemToCache(BlogStore.PATH, { posts: this.posts });
        } catch (error) {
            logger.error(error);
            this.posts = BlogStore.FALLBACK_POSTS.map((post) => this.processPost(post));
        } finally {
            this.isLoading = false;
        }
    };
}
