import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import Hubspot from 'main/common/Hubspot';
import isomorphicLocalStorage from 'modules/isomorphic-local-storage';
import gtagEvent from 'utils/gtag-event';
import isBrowser from 'utils/is-browser';

const HUB_SPOT_SIGN_UP_KEY = 'hub_spot_sign_up';

export function useHubSpotOauthRedirect({
    baseUrl,
    marketingAgreement
}: {
    baseUrl: string;
    marketingAgreement?: boolean;
}) {
    const signUpKey = useMemo(() => uuid(), []);

    const backTo = useMemo(() => {
        const [path, _search] = baseUrl.split('?');
        return path;
    }, [baseUrl]);

    const onClick = useCallback(() => {
        isomorphicLocalStorage.setItem(HUB_SPOT_SIGN_UP_KEY, signUpKey);
        return true;
    }, [signUpKey]);

    const parameters = useMemo(() => {
        const [_path, search = ''] = baseUrl.split('?');
        const searchParams = new URLSearchParams(search);
        searchParams.set(HUB_SPOT_SIGN_UP_KEY, signUpKey);
        if (marketingAgreement) {
            searchParams.set('marketing_agreement', '1');
        }
        return [...searchParams.entries()].reduce((output, [key, value]) => ({ ...output, [key]: value }), {});
    }, [baseUrl, marketingAgreement, signUpKey]);

    return { backTo, onClick, parameters };
}

export function handleHubSpotOauthRedirect({
    email,
    username,
    marketingAgreement
}: {
    email: string;
    username: string;
    marketingAgreement?: boolean;
}) {
    if (isBrowser()) {
        const url = new URL(window.location.href);
        const queryKey = url.searchParams.get(HUB_SPOT_SIGN_UP_KEY);
        const localKey = isomorphicLocalStorage.getItem(HUB_SPOT_SIGN_UP_KEY);

        if (queryKey && localKey && queryKey === localKey) {
            Hubspot.send(Hubspot.SIGNUP, { email, username });
            gtagEvent('login-oauth-success');
            if (marketingAgreement) {
                Hubspot.send(Hubspot.MARKETING_AGREEMENT, { email });
            }
        }

        isomorphicLocalStorage.removeItem(HUB_SPOT_SIGN_UP_KEY);
        url.searchParams.delete(HUB_SPOT_SIGN_UP_KEY);
        window.history.replaceState({}, '', url.href);
    }
}
