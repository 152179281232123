import { isEmpty } from 'lodash';

export default function isEmptyRecursive(value: unknown): boolean {
    if (isEmpty(value)) {
        return true;
    }
    if (Array.isArray(value)) {
        return value.length === 0 || value.every((item) => isEmpty(item));
    }
    if (value instanceof Set || value instanceof Map) {
        return value.size === 0;
    }
    if (typeof value === 'object' && value !== null) {
        const values = Object.values(value);
        return values.length === 0 || values.every((value) => isEmpty(value));
    }
    return false;
}
