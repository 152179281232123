import { get } from 'lodash';

import { errorToJson, jsonReplacer } from 'services/logger/utils';

import { Logger } from './types';

function stringify(obj: object) {
    const mode = window.__CONFIG__.mode;
    return mode === 'development' ? JSON.stringify(obj, jsonReplacer, 2) : JSON.stringify(obj, jsonReplacer);
}

function format(level: string, message: string | object, meta?: object) {
    const appName = 'front_end_browser';
    const timestamp = new Date().toISOString();
    const environment = window.__CONFIG__.environment;

    const output: Record<string, string> = { appName, environment, level, timestamp };

    if (typeof message === 'string') {
        if (meta) {
            if (meta instanceof Error) {
                Object.assign(output, errorToJson(meta));
            } else {
                Object.assign(output, meta);
            }
            output.message = [message, get(meta, 'message')].filter(Boolean).join(' ');
        } else {
            output.message = message;
        }
    }
    if (typeof message === 'object' && message !== null) {
        if (message instanceof Error) {
            Object.assign(output, errorToJson(message));
        } else if ('message' in message) {
            Object.assign(output, message);
        } else {
            output.message = stringify(message);
        }
    }

    return stringify(output);
}

export const logger: Logger = {
    log: (...args) => {
        console.log(format('log', ...(args as [never])));
        return logger;
    },
    info: (...args) => {
        console.info(format('info', ...(args as [never])));

        return logger;
    },
    error: (...args) => {
        console.error(format('error', ...(args as [never])));

        return logger;
    },
    warn: (...args) => {
        console.warn(format('warn', ...(args as [never])));

        return logger;
    },
    debug: (...args) => {
        console.debug(format('debug', ...(args as [never])));

        return logger;
    }
};
